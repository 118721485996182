<template>
  <v-btn @click.prevent="oauthAccessToken">
    <slot></slot>
    <v-dialog v-model="modal" max-width="580">
      <v-textarea
        autofocus
        ref="textarea"
        :value="token"
        dense
        outlined
      />
      <div class="d-flex align-center justify-space-between">
        <v-btn color="success" @click="copy">
          <v-icon small left>mdi-content-copy</v-icon>
          Скопировать
        </v-btn>
        <v-btn @click="close">
          <v-icon small left>mdi-close</v-icon>
          Закрыть
        </v-btn>
      </div>
    </v-dialog>
  </v-btn>
</template>

<script>
import userService from '../../services/users'
import { Notification } from 'element-ui'

export default {
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      token: null,
      copied: false,
    }
  },
  methods: {
    open (token) {
      this.modal = true
      this.token = token
    },
    close () {
      this.modal = false
      this.token = null
    },
    copy () {
      const container = document.querySelector('.v-dialog')
      this.$copyText(this.token, container).then(() => {
        Notification({
          message: 'Скопировано в буфер обмена',
          type: 'success',
          duration: 3000
        })
      })
    },
    async oauthAccessToken () {
      const resp = await userService.oauthAccessToken(this.userId, 'mobile')
      this.open(JSON.stringify(resp.body))
    }
  }
}
</script>
